<template>
    <v-form ref="payment" v-model="valid">
        <form-section sectionTitle="Application Payment" wideFirstCol>
            <template v-slot:col-1>
                <form-element-group>
                    <template v-slot:col-1>
                        <applicant-payment
                            :valid.sync="valid"
                            :applicant="applicant"
                            :application="application"
                            @payment-complete="paymentComplete"
                            @payment-error="paymentError"
                        ></applicant-payment>
                    </template>
                </form-element-group>
            </template>
            <template v-slot:col-2></template>
        </form-section>
    </v-form>
</template>
<script>
export default {
    name: "applicant-checkout",
    components: {
        ApplicantPayment: () => import("./ApplicantPayment"),
    },
    props: {
        applicant: {
            type: Object,
            required: true,
        },
        application: {
            type: Object,
            required: true,
        },
        step: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            valid: false,
        };
    },
    methods: {
        paymentComplete(payment) {
            console.log(payment);
            this.$emit("next", parseInt(this.step) + 1);
        },
        paymentError(payment) {
            console.log("Payment Error %o", payment);
        },
    },
};
</script>

<style scoped>
</style>