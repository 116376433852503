var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "payment",
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c("form-section", {
        attrs: { sectionTitle: "Application Payment", wideFirstCol: "" },
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("form-element-group", {
                  scopedSlots: _vm._u([
                    {
                      key: "col-1",
                      fn: function() {
                        return [
                          _c("applicant-payment", {
                            attrs: {
                              valid: _vm.valid,
                              applicant: _vm.applicant,
                              application: _vm.application
                            },
                            on: {
                              "update:valid": function($event) {
                                _vm.valid = $event
                              },
                              "payment-complete": _vm.paymentComplete,
                              "payment-error": _vm.paymentError
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ]
            },
            proxy: true
          },
          {
            key: "col-2",
            fn: function() {
              return undefined
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }